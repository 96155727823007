import { PureComponent } from "react";
import cur_prj_1 from "../../../img/BRBN_Gr_s1/baraban_gr_section_one_1.jpg";
import cur_prj_2 from "../../../img/BRBN_Gr_s1/baraban_gr_section_one_2.jpg";
import cur_prj_3 from "../../../img/BRBN_Gr_s1/baraban_gr_section_one_3.jpg";
import cur_prj_4 from "../../../img/BRBN_Gr_s1/baraban_gr_section_one_4.jpg";
import cur_prj_5 from "../../../img/BRBN_Gr_s1/baraban_gr_section_one_5.jpg";
import cur_prj_6 from "../../../img/BRBN_Gr_s1/baraban_gr_section_one_6.jpg";
import cur_prj_7 from "../../../img/BRBN_Gr_s1/baraban_gr_section_one_7.jpg";
import CurrentPrj from "../../../components/CurrentPrj/CurrentPrj.js";

class BRBnGrS1 extends PureComponent {
  render() {
    const { lang } = this.props;

    const imgEl = [
      cur_prj_1,
      cur_prj_2,
      cur_prj_3,
      cur_prj_4,
      cur_prj_5,
      cur_prj_6,
      cur_prj_7
    ];

    const idUniq = 243;

    const textDescribe =
      "Система автоматизації Crestron забезпечує повний контроль над будинком, поєднуючи зручність і безпеку. Вона дозволяє керувати кліматом, підтримуючи комфортну температуру в приміщеннях. Автоматизоване освітлення адаптується до часу доби та присутності людей. Інтеграція з аудіосистемою Bower&Wilkins створює ідеальну атмосферу для відпочинку. Система моніторить бойлер великого об’єму та його критично важливі параметри, реалізований моніторинг споживання енергії. Встановлена система антизатоплення миттєво реагує на витік води, запобігаючи пошкодженням. Crestron забезпечує розумне управління усіма процесами, роблячи дім безпечним і технологічним. Архітектор: BARABAN ARCHITECTURE CREW";

      const textDescribeEng =
      "The Crestron automation system provides complete control over your home, combining convenience and security. It allows you to control the climate, maintaining a comfortable room temperature. Automated lighting adapts to the time of day and the presence of people. Integration with the Bower & Wilkins audio system creates the perfect atmosphere for relaxation. The system monitors the large-volume boiler and its critical parameters, and energy consumption is monitored. The installed anti-flooding system instantly responds to water leaks, preventing damage. Crestron provides smart control of all processes, making the house safe and technologically advanced. Design by BARABAN ARCHITECTURE CREW";

    return (
      <>
        <CurrentPrj
          name="Elite apartments"
          cur_prj={imgEl}
          id_prj={idUniq}
          textDescription={textDescribe}
          textDescriptionEng={textDescribeEng}
          lang={lang}
        />
      </>
    );
  }
}

export default BRBnGrS1;
