import { PureComponent } from "react";
import cur_prj_1 from "../../../img/BARABAN_Resident/baraban_residence_1.jpg";
import cur_prj_2 from "../../../img/BARABAN_Resident/baraban_residence_2.jpg";
import cur_prj_3 from "../../../img/BARABAN_Resident/baraban_residence_3.jpg";
import cur_prj_4 from "../../../img/BARABAN_Resident/baraban_residence_4.jpg";
import cur_prj_5 from "../../../img/BARABAN_Resident/baraban_residence_5.jpg";
import cur_prj_6 from "../../../img/BARABAN_Resident/baraban_residence_6.jpg";
import cur_prj_7 from "../../../img/BARABAN_Resident/baraban_residence_7.jpg";
import CurrentPrj from "../../../components/CurrentPrj/CurrentPrj.js";

class BRBnResident extends PureComponent {
  render() {
    const { lang } = this.props;

    const imgEl = [
      cur_prj_1,
      cur_prj_2,
      cur_prj_3,
      cur_prj_4,
      cur_prj_5,
      cur_prj_6,
      cur_prj_7
    ];

    const idUniq = 245;

    const textDescribe =
      "Система Crestron забезпечує розумний енергоменеджмент, оптимізуючи витрати ресурсів. Сценарії автоматичного контролю енергоспоживання дозволяють вимикати непріоритетних споживачів, загалом у сценаріях беруть участь і такі споживачі як зарядки електромашин, електроплити, витяжки тощо. Інтелектуальне керування приточно-витяжною вентиляцією адаптоване під режими активності мешканців. Управління кліматом гарантує комфорт у будь-яку пору року. Різні сценарії освітлення, адаптовані під власників, створюють ідеальну атмосферу. Присутні різні швидкі сценарії, як наприклад, світлові сценарії для прибирання чи паті. Crestron забезпечує безпеку, комфорт і ефективне управління будинком. Архітектор: BARABAN ARCHITECTURE CREW";

      const textDescribeEng =
      "The Crestron system provides smart energy management, optimizing resource consumption. Automatic energy control scenarios allow you to turn off non-priority consumers, generally including consumers such as charging electric vehicles, electric stoves, hoods, etc. Intelligent control of the supply and exhaust ventilation is adapted to the activity modes of the residents. Climate control guarantees comfort at any time of the year. Different lighting scenarios adapted to the owners create the perfect atmosphere. Various quick scenarios are available, such as lighting scenarios for cleaning or parties. Crestron provides security, comfort and efficient home management. Design by BARABAN ARCHITECTURE CREW";

    return (
      <>
        <CurrentPrj
          name="Energy-efficient house"
          cur_prj={imgEl}
          id_prj={idUniq}
          textDescription={textDescribe}
          textDescriptionEng={textDescribeEng}
          lang={lang}
        />
      </>
    );
  }
}

export default BRBnResident;
