import { useState } from "react";
import styles from "./CurrentPrj.module.scss";
import Footer from "../Footer/Footer";
import "./slick.css";
import "./slick-theme.css";
import Slider from "react-slick";
import { NavLink } from "react-router-dom";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", zIndex: "3" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", left: "1px" }}
      onClick={onClick}
    />
  );
}

const CurrentPrj = (props) => {
  const { name, cur_prj, id_prj, textDescription, textDescriptionEng, lang } = props;

  const [idLink, setIdLink] = useState(id_prj);

  const chngLinkUp = (val) => {
    setIdLink((id) => {
      id = val + 1;
      return id;
    });
  };

  const chngLinkDn = (val) => {
    setIdLink((id) => {
      id = val;
      return id;
    });
  };

  const settings = {
    dots: false,
    infinite: true,
    centerMode: true,
    centerPadding: "0px",
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
          centerMode: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
          centerMode: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          infinite: true,
          centerMode: true,
          arrows: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };
  return (
    <div className={styles.engineRoot}>
      <div className={styles.engineer_proj}>
        <div className={styles.cur_prj}>
          <div className={styles.cur_prj_light_1}>
            <div className={styles.cur_prj_div_palochka}>
              <div className={styles.cur_prj_palochka}></div>
              <div className={styles.cur_prj_palochka_horizont}></div>
            </div>
            <h1 className={styles.cur_prj_text_chapter}>{name}</h1>
            <div className={styles.cur_prj_for800h}>
              <div className={styles.cur_prj_btnlist}>
                {id_prj > 231 && (
                  <NavLink
                    to={`/project_c${idLink - 1}`}
                    style={({ isActive, isPending }) => {
                      return {
                        fontWeight: isActive ? "bold" : "",
                        color: isPending ? "darkgrey" : "black",
                        textDecoration: "none",
                        display: "contents",
                      };
                    }}
                  >
                    <button
                      className={styles.cur_prj_prevprj}
                      onClick={() => {
                        chngLinkDn(id_prj);
                      }}
                    >
                      <i className={styles.cur_prj_arrow_left}></i>{lang ? "PREVIOUS" : "ПОПЕРЕДНІЙ"}
                    </button>
                  </NavLink>
                )}
                <NavLink
                  to={"/ourProjects"}
                  style={({ isActive, isPending }) => {
                    return {
                      fontWeight: isActive ? "bold" : "",
                      color: isPending ? "darkgrey" : "black",
                      textDecoration: "none",
                      display: "contents",
                    };
                  }}
                >
                  <button className={styles.cur_prj_allprj}>{lang ? "ALL PROJECTS" : "ВСІ ПРОЄКТИ"}</button>
                </NavLink>
                {id_prj < 245 && (
                  <NavLink
                    to={`/project_c${idLink + 1}`}
                    style={({ isActive, isPending }) => {
                      return {
                        fontWeight: isActive ? "bold" : "",
                        color: isPending ? "darkgrey" : "black",
                        textDecoration: "none",
                        display: "contents",
                      };
                    }}
                  >
                    <button
                      className={styles.cur_prj_nextprj}
                      onClick={() => {
                        chngLinkUp(id_prj);
                      }}
                    >
                      {lang ? "NEXT" : "НАСТУПНИЙ"}<i className={styles.cur_prj_arrow_right}></i>
                    </button>
                  </NavLink>
                )}
              </div>

              <div className={styles.cur_prj_photolist}>
                <div className={styles.carousel}>
                  <div className={styles.cur_prj_zone_prev}></div>
                  <Slider {...settings}>
                    {cur_prj.map((el, index) => (
                      <img
                        key={index}
                        className={styles.cur_prj_foto_1}
                        src={el}
                        alt={index}
                      ></img>
                    ))}
                  </Slider>
                  <div className={styles.cur_prj_zone_next}></div>
                </div>
              </div>
            </div>
            <h1 className={styles.cur_prj_textprj}>{lang ? "ABOUT PROJECT" : "ПРО ПРОЄКТ"}</h1>
            <div className={styles.cur_prj_describe}>
             {lang ? `${textDescriptionEng}` : `${textDescription}`}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.additDiv}></div>
      <Footer />
    </div>
  );
};

export default CurrentPrj;
