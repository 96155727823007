import { PureComponent } from "react";
import cur_prj_1 from "../../../img/BRBN_Gr_s2/baraban_gr_section_two_1.jpg";
import cur_prj_2 from "../../../img/BRBN_Gr_s2/baraban_gr_section_two_2.jpg";
import cur_prj_3 from "../../../img/BRBN_Gr_s2/baraban_gr_section_two_3.jpg";
import cur_prj_4 from "../../../img/BRBN_Gr_s2/baraban_gr_section_two_4.jpg";
import cur_prj_5 from "../../../img/BRBN_Gr_s2/baraban_gr_section_two_5.jpg";
import cur_prj_6 from "../../../img/BRBN_Gr_s2/baraban_gr_section_two_6.jpg";
import cur_prj_7 from "../../../img/BRBN_Gr_s2/baraban_gr_section_two_7.jpg";
import CurrentPrj from "../../../components/CurrentPrj/CurrentPrj.js";

class BRBnGrS2 extends PureComponent {
  render() {
    const { lang } = this.props;

    const imgEl = [
      cur_prj_1,
      cur_prj_2,
      cur_prj_3,
      cur_prj_4,
      cur_prj_5,
      cur_prj_6,
      cur_prj_7
    ];

    const idUniq = 244;

    const textDescribe =
      "Система автоматизації Crestron забезпечує повний контроль над будинком. Мультифункціональні кейпади та кнопки дозволяють швидко керувати всіма функціями. Автоматизоване освітлення підтримує різні сценарії світла, які користувач може створювати та зберігати у швидкі пресети. Інтеграція з Bower&Wilkins забезпечує якісний звук. Автоматизоване управління кліматом підтримує комфорт. Моніторинг споживання електроенергії робить оселю енергоефективною. Система антизатоплення миттєво реагує на витік води. Crestron робить житло технологічним, комфортним і безпечним. Архітектор: BARABAN ARCHITECTURE CREW";

      const textDescribeEng =
      "The Crestron automation system provides complete control over your home. Multifunctional keypads and buttons allow you to quickly control all functions. Automated lighting supports various light scenarios that the user can create and save to quick presets. Integration with Bower & Wilkins provides high-quality sound. Automated climate control maintains comfort. Monitoring of electricity consumption makes the home energy efficient. The anti-flooding system instantly responds to water leaks. Crestron makes your home technologically advanced, comfortable and safe. Design by BARABAN ARCHITECTURE CREW";

    return (
      <>
        <CurrentPrj
          name="Elegant functionality"
          cur_prj={imgEl}
          id_prj={idUniq}
          textDescription={textDescribe}
          textDescriptionEng={textDescribeEng}
          lang={lang}
        />
      </>
    );
  }
}

export default BRBnGrS2;
