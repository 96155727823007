import { PureComponent } from "react";
import styles from "./OurProjects.module.scss";
import Footer from "../../components/Footer/Footer";
import all_prj_1 from "../../img/all-1.jpg";
import all_prj_2 from "../../img/all-2.jpg";
import all_prj_3 from "../../img/all-3.jpg";
import all_prj_4 from "../../img/all-4.jpg";
import all_prj_5 from "../../img/all-5.jpg";
import all_prj_6 from "../../img/all-6.jpg";
import all_prj_7 from "../../img/all-7.jpg";
import all_prj_8 from "../../img/all-8.jpg";
import all_prj_9 from "../../img/all-9.jpg";
import all_prj_10 from "../../img/all-10.jpg";
import all_prj_11 from "../../img/all-11.jpg";
import all_prj_12 from "../../img/all-12.jpg";
import all_prj_13 from "../../img/all-13.jpg";
import all_prj_14 from "../../img/all-14.jpg";
import all_prj_15 from "../../img/all-15.jpg";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

class OurProjects extends PureComponent {
  render() {
    const { lang } = this.props;
    return (
      <div className={styles.engineRoot}>
        <Helmet>
          <title>{lang ? "Our projects" : "Наші проєкти"}</title>
          <meta
            name="description"
            content="Реалізовані проєкти від D8engineering | Проектування та автоматизації | Реалізуємо проекти будь-якої складності | Crestron"
          />
           <link rel="canonical" href="https://d8.com.ua/ourprojects" />
        </Helmet>
        <div className={styles.engineer_proj}>
          <div className={styles.all_prj}>
            <div className={styles.all_prj_light_1}>
              <div className={styles.all_prj_palochka}></div>
              <h1 className={styles.all_prj_text_chapter}>
                {lang ? "OUR PROJECTS" : "НАШІ ПРОЄКТИ"}
              </h1>
              <div className={styles.all_prj_grid_foto}>
                <NavLink
                  to={"/project_c235"}
                  style={({ isActive, isPending }) => {
                    return {
                      fontWeight: isActive ? "bold" : "",
                      color: isPending ? "darkgrey" : "black",
                      textDecoration: "none",
                      display: "contents",
                    };
                  }}
                >
                  <img
                    className={styles.all_prj_foto_1}
                    src={all_prj_1}
                    alt="modern project"
                  ></img>
                </NavLink>
                <NavLink
                  to={"/project_c237"}
                  style={({ isActive, isPending }) => {
                    return {
                      fontWeight: isActive ? "bold" : "",
                      color: isPending ? "darkgrey" : "black",
                      textDecoration: "none",
                      display: "contents",
                    };
                  }}
                >
                  <img
                    className={styles.all_prj_foto_2}
                    src={all_prj_2}
                    alt="penthouse Skyline"
                  ></img>
                </NavLink>
                <NavLink
                  to={"/project_c234"}
                  style={({ isActive, isPending }) => {
                    return {
                      fontWeight: isActive ? "bold" : "",
                      color: isPending ? "darkgrey" : "black",
                      textDecoration: "none",
                      display: "contents",
                    };
                  }}
                >
                  <img
                    className={styles.all_prj_foto_3}
                    src={all_prj_3}
                    alt="makhno Office"
                  ></img>
                </NavLink>
                <NavLink
                  to={"/project_c239"}
                  style={({ isActive, isPending }) => {
                    return {
                      fontWeight: isActive ? "bold" : "",
                      color: isPending ? "darkgrey" : "black",
                      textDecoration: "none",
                      display: "contents",
                    };
                  }}
                >
                  <img
                    className={styles.all_prj_foto_4}
                    src={all_prj_4}
                    alt="shkrub House"
                  ></img>
                </NavLink>
                <NavLink
                  to={"/project_c231"}
                  style={({ isActive, isPending }) => {
                    return {
                      fontWeight: isActive ? "bold" : "",
                      color: isPending ? "darkgrey" : "black",
                      textDecoration: "none",
                      display: "contents",
                    };
                  }}
                >
                  <img
                    className={styles.all_prj_foto_5}
                    src={all_prj_5}
                    alt="academy Dtek"
                  ></img>
                </NavLink>
                <NavLink
                  to={"/project_c236"}
                  style={({ isActive, isPending }) => {
                    return {
                      fontWeight: isActive ? "bold" : "",
                      color: isPending ? "darkgrey" : "black",
                      textDecoration: "none",
                      display: "contents",
                    };
                  }}
                >
                  <img
                    className={styles.all_prj_foto_6}
                    src={all_prj_6}
                    alt="pechersky"
                  ></img>
                </NavLink>
                <NavLink
                  to={"/project_c240"}
                  style={({ isActive, isPending }) => {
                    return {
                      fontWeight: isActive ? "bold" : "",
                      color: isPending ? "darkgrey" : "black",
                      textDecoration: "none",
                      display: "contents",
                    };
                  }}
                >
                  <img
                    className={styles.all_prj_foto_7}
                    src={all_prj_7}
                    alt="vatra"
                  ></img>
                </NavLink>
                <NavLink
                  to={"/project_c232"}
                  style={({ isActive, isPending }) => {
                    return {
                      fontWeight: isActive ? "bold" : "",
                      color: isPending ? "darkgrey" : "black",
                      textDecoration: "none",
                      display: "contents",
                    };
                  }}
                >
                  <img
                    className={styles.all_prj_foto_8}
                    src={all_prj_8}
                    alt="art apartment"
                  ></img>
                </NavLink>
                <NavLink
                  to={"/project_c233"}
                  style={({ isActive, isPending }) => {
                    return {
                      fontWeight: isActive ? "bold" : "",
                      color: isPending ? "darkgrey" : "black",
                      textDecoration: "none",
                      display: "contents",
                    };
                  }}
                >
                  <img
                    className={styles.all_prj_foto_9}
                    src={all_prj_9}
                    alt="classic house"
                  ></img>
                </NavLink>

                <NavLink
                  to={"/project_c238"}
                  style={({ isActive, isPending }) => {
                    return {
                      fontWeight: isActive ? "bold" : "",
                      color: isPending ? "darkgrey" : "black",
                      textDecoration: "none",
                      display: "contents",
                    };
                  }}
                >
                  <img
                    className={styles.all_prj_foto_10}
                    src={all_prj_10}
                    alt="private house"
                  ></img>
                </NavLink>

                
                <NavLink
                  to={"/project_c241"}
                  style={({ isActive, isPending }) => {
                    return {
                      fontWeight: isActive ? "bold" : "",
                      color: isPending ? "darkgrey" : "black",
                      textDecoration: "none",
                      display: "contents",
                    };
                  }}
                >
                  <img
                    className={styles.all_prj_foto_11}
                    src={all_prj_11}
                    alt="penthouse diamond hill"
                  ></img>
                </NavLink>

                
                <NavLink
                  to={"/project_c242"}
                  style={({ isActive, isPending }) => {
                    return {
                      fontWeight: isActive ? "bold" : "",
                      color: isPending ? "darkgrey" : "black",
                      textDecoration: "none",
                      display: "contents",
                    };
                  }}
                >
                  <img
                    className={styles.all_prj_foto_12}
                    src={all_prj_12}
                    alt="skyline terrace design"
                  ></img>
                </NavLink>

                <NavLink
                  to={"/project_c243"}
                  style={({ isActive, isPending }) => {
                    return {
                      fontWeight: isActive ? "bold" : "",
                      color: isPending ? "darkgrey" : "black",
                      textDecoration: "none",
                      display: "contents",
                    };
                  }}
                >
                  <img
                    className={styles.all_prj_foto_13}
                    src={all_prj_13}
                    alt="penthouse design energymanagement baraban"
                  ></img>
                </NavLink>

                <NavLink
                  to={"/project_c244"}
                  style={({ isActive, isPending }) => {
                    return {
                      fontWeight: isActive ? "bold" : "",
                      color: isPending ? "darkgrey" : "black",
                      textDecoration: "none",
                      display: "contents",
                    };
                  }}
                >
                  <img
                    className={styles.all_prj_foto_14}
                    src={all_prj_14}
                    alt="design luxury modern baraban architecture crew"
                  ></img>
                </NavLink>

                <NavLink
                  to={"/project_c245"}
                  style={({ isActive, isPending }) => {
                    return {
                      fontWeight: isActive ? "bold" : "",
                      color: isPending ? "darkgrey" : "black",
                      textDecoration: "none",
                      display: "contents",
                    };
                  }}
                >
                  <img
                    className={styles.all_prj_foto_15}
                    src={all_prj_15}
                    alt="design automation residential baraban"
                  ></img>
                </NavLink>
              </div>
              <div className={styles.all_prj_dark_palochka}></div>
              <h1 className={styles.all_prj_text}>
                {lang
                  ? "LET'S TALK ABOUT YOUR PROJECT"
                  : "ДАВАЙТЕ ПОГОВОРИМО ПРО ВАШ ПРОЄКТ"}
              </h1>
              {lang ? (
                <NavLink
                  to={"/feedback_en"}
                  style={({ isActive, isPending }) => {
                    return {
                      fontWeight: isActive ? "bold" : "",
                      color: isPending ? "darkgrey" : "black",
                      textDecoration: "none",
                    };
                  }}
                >
                  <button className={styles.btn_write_us}>CONTACT US</button>
                </NavLink>
              ) : (
                <NavLink
                  to={"/feedback"}
                  style={({ isActive, isPending }) => {
                    return {
                      fontWeight: isActive ? "bold" : "",
                      color: isPending ? "darkgrey" : "black",
                      textDecoration: "none",
                    };
                  }}
                >
                  <button className={styles.btn_write_us}>
                    ЗВ'ЯЗАТИСЬ З НАМИ
                  </button>
                </NavLink>
              )}
            </div>
          </div>
        </div>
        <Footer lang={lang} />
      </div>
    );
  }
}

export default OurProjects;
